import { defineStore } from 'pinia'
import { LocalStorage } from 'quasar'
import { ref, watch } from 'vue'
import type { RouteLocationRaw } from 'vue-router'
import { useRouter } from 'vue-router'

import api from '@/vantool/utils/axios'
import createLogger from '@/vantool/utils/logger'
import type { BusinessModel, BusinessFeatureFlag } from '@/vantool/stores/b2b/businesses'
import type { ScheduledStop } from '@/vantool/stores/scheduled-stops'

const logger = createLogger('Account')

interface Account {
  id: number
  first_name: string
  last_name: string
  email: string
  phone_number: string
  is_yepler: boolean
  is_external_mechanic: boolean
  current_van: {
    id: string
    name: string
    current_scheduled_stop: ScheduledStop | null
  } | null
}

interface AccountUpdatePayload {
  first_name?: string
  last_name?: string
  email?: string
  phone_number?: string
  current_van?: string
}

interface CountryConfig {
  currency: {
    code: string
    locale: string
    symbol: string
  }
}

export interface UpcomingStop {
  id: number
  location: {
    id: number
    name: string
    latitude: number
    longitude: number
    city: string | null
    businesses: {
      id: number
      name: string
      models: BusinessModel[]
      feature_flags: BusinessFeatureFlag[]
    }[]
  }
  van: {
    id: number
    name: string
  } | null
  start_time: string
  end_time: string
  internal_notes: string
}

export enum LogoutReason {
  REFRESH_TOKEN_EXPIRED = 'REFRESH_TOKEN_EXPIRED',
  REFRESH_TOKEN_MALFORMED = 'REFRESH_TOKEN_MALFORMED'
}

export const useAccountStore = defineStore('account', () => {
  const router = useRouter()

  const accessToken = ref<string | null>(LocalStorage.getItem('accessToken'))

  watch(accessToken, () => {
    if (accessToken.value) {
      logger.debug('Access token changed. Saving to local storage...')
      LocalStorage.set('accessToken', accessToken.value)
    } else {
      logger.debug('Access token got unset. Removing from local storage...')
      LocalStorage.remove('accessToken')
    }
  })

  const refreshToken = ref<string | null>(LocalStorage.getItem('refreshToken'))

  const logoutTimer = ref<number>()
  const isLoggingOut = ref(false)
  watch(
    refreshToken,
    () => {
      if (!refreshToken.value) {
        logger.debug('Refresh token got unset. Removing from local storage...')
        LocalStorage.remove('refreshToken')
        logout()
        return
      }

      logger.debug('Refresh token changed. Saving to local storage...')
      LocalStorage.set('refreshToken', refreshToken.value)
      setLogoutTimer()
      getAccount()
      getCountryConfig()
    },
    { immediate: true }
  )

  const isRefreshingAccessToken = ref(false)
  const accessTokenRefreshCallbacks = ref<(() => void)[]>([])

  const account = ref<Account>()
  const countryConfig = ref<CountryConfig>()

  async function login(
    email: string,
    password: string,
    redirectTo: RouteLocationRaw = { name: 'board' }
  ) {
    logger.debug('Logging in with redirect to:', redirectTo)
    let response
    try {
      response = await api.post('/internal/auth/token/', { email, password })
    } catch (error: any) {
      if (error.response?.status === 401 || error.response?.status === 403)
        error.message = 'Invalid email or password'
      else {
        error.message = `Login failed: ${error.message}`
      }

      throw error
    }

    accessToken.value = response.data.access
    refreshToken.value = response.data.refresh

    LocalStorage.set('lastLoggedInAt', Date.now())

    await getAccount()
    await getCountryConfig()

    logger.debug('Redirecting to:', redirectTo)
    router.push(redirectTo)
  }

  function setLogoutTimer() {
    try {
      const refreshTokenExpiresIn = getExpiresIn()
      if (!refreshTokenExpiresIn) return
      clearTimeout(logoutTimer.value)
      logger.debug('Setting logout timer with delay of', refreshTokenExpiresIn, 'ms')
      logoutTimer.value = window.setTimeout(
        () => logout(LogoutReason.REFRESH_TOKEN_EXPIRED),
        refreshTokenExpiresIn
      )
    } catch (error: any) {
      logger.warn('Refresh token is malformed. Logging out...')
      logout(LogoutReason.REFRESH_TOKEN_MALFORMED)
    }
  }

  function getExpiresIn() {
    if (!refreshToken.value) return null
    const refreshTokenExpiresAt = JSON.parse(atob(refreshToken.value!.split('.')[1])).exp
    return refreshTokenExpiresAt * 1000 - Date.now()
  }

  async function logout(reason?: LogoutReason, redirectPathAfterLogin?: string) {
    logger.debug('Logging out with reason:', reason)
    if (isLoggingOut.value) {
      logger.debug('Aborting logout because another logout is already in progress.')
      return
    }

    isLoggingOut.value = true
    logger.debug('Clearing tokens...')
    accessToken.value = null
    refreshToken.value = null

    if (router.currentRoute.value.name === 'login') {
      logger.debug('Already on login page. Not redirecting.')
      await router.replace({
        name: 'login',
        query: { reason, next: router.currentRoute.value.query.next }
      })
    } else {
      logger.debug('Redirecting to login page with reason', reason)
      await router.push({
        name: 'login',
        query: { reason, next: redirectPathAfterLogin || router.currentRoute.value.fullPath }
      })
    }

    isLoggingOut.value = false
  }

  function requestAccessTokenRefresh() {
    logger.debug('Requesting access token refresh')
    if (isRefreshingAccessToken.value) {
      logger.debug('Access token is already being refreshed. Waiting for refresh to finish...')
      return new Promise((resolve) => accessTokenRefreshCallbacks.value.push(() => resolve(true)))
    }

    logger.debug('Access token is not being refreshed. Refreshing now...')
    return refreshAccessToken()
  }

  async function refreshAccessToken() {
    logger.debug('Refreshing access token...')
    isRefreshingAccessToken.value = true

    try {
      const response = await api.post(
        '/internal/auth/token/refresh/',
        { refresh: refreshToken.value },
        { skipJWTRefresh: true }
      )
      accessToken.value = response.data.access
      logger.debug('Received new access token')
      logger.debug('Calling access token refresh callbacks...')
      accessTokenRefreshCallbacks.value.forEach((callback) => callback())
    } catch (error: any) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        logger.warn('Refresh token most likely expired. Logging out...')
        logout(LogoutReason.REFRESH_TOKEN_EXPIRED)
      } else {
        error.message = `Failed refreshing access token: ${error.message}`
        throw error
      }
    } finally {
      accessTokenRefreshCallbacks.value = []
      isRefreshingAccessToken.value = false
    }
  }

  async function getAccount() {
    let response
    try {
      response = await api.get('/internal/vantool/account')
    } catch (error: any) {
      error.message = `Failed loading account: ${error.message}`
      logger.error(error.message)
      throw error
    }

    account.value = response.data
    if (!account.value) return
  }

  async function updateAccount(payload: AccountUpdatePayload) {
    if (!account.value) throw Error('Account not loaded')

    try {
      account.value = await api.patch(`/internal/vantool/account`, payload)
    } catch (error: any) {
      error.message = `Failed updating account: ${error.message}`
      logger.error(error.message)
      throw error
    }
  }

  async function getCountryConfig() {
    let response
    try {
      response = await api.get('/country-config/')
    } catch (error: any) {
      error.message = `Failed loading country config: ${error.message}`
      logger.error(error.message)
      throw error
    }

    countryConfig.value = response.data
  }

  const upcomingStops = ref<UpcomingStop[]>([])
  async function getUpcomingStops() {
    let response
    try {
      response = await api.get('/internal/vantool/upcoming-shifts')
    } catch (error: any) {
      error.message = `Failed loading upcoming shifts: ${error.message}`
      logger.error(error.message)
      throw error
    }

    upcomingStops.value = response.data
  }

  const lastLoggedInAt = LocalStorage.getItem('lastLoggedInAt') as number
  const expiresIn = getExpiresIn()
  if (lastLoggedInAt && expiresIn) {
    const now = Date.now()
    const loggedInToday = now - lastLoggedInAt < 24 * 60 * 60 * 1000
    if (!loggedInToday) {
      logger.warn('User has not logged in today. Logging out to prevent auto-logout during shift.')
      logout(LogoutReason.REFRESH_TOKEN_EXPIRED)
    }
  }

  return {
    accessToken,
    account,
    countryConfig,
    upcomingStops,

    login,
    logout,
    requestAccessTokenRefresh,
    getAccount,
    updateAccount,
    getCountryConfig,
    getUpcomingStops
  }
})
